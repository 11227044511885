import { useState } from 'react';
// mantine
import { Loader, Button } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
// アイコン
import { IconCheck, IconCopy } from '@tabler/icons-react';

// APIのURL
const envApiUrl = process.env.REACT_APP_API_URL;

function TextCopyButton(props: any) {
	// テキストコピーステート
	const [copied, setCopied] = useState<boolean>(false);
	const clipboard = useClipboard({ timeout: 2000 });

	// テキストコピー
	const TextCopy = async (id: string) => {
		// ローディング画面開始
		setCopied(true)
		try {
			// APIURLの生成
			const apiURL = `${envApiUrl}/gettext.php?id=${id}`
			// APIからテキストを取得するためのリクエストを送信
			const response = await fetch(apiURL);
			if (!response.ok) {
				throw new Error('Failed to fetch data');
			}
			const data = await response.json();
			if (data.result.length === 0) {
				// エラー内容をクリップボードにコピー
				clipboard.copy(`「${id}」のデータを取得できませんでした`);
			} else {
				// 取得したテキストをクリップボードにコピー
				clipboard.copy(data.result[0].content);
			}
			setCopied(false)  // ローディング画面終了
		} catch (error) {
			console.error('Error copying text:', error);
			setCopied(false)  // ローディング画面終了
		}
	};

	return (
		<Button
			leftSection={clipboard.copied ? (
				<IconCheck size={14} />
			) : (
				copied ? (
					<Loader size="xs" color="blue" />
				) : (
					<IconCopy size={14} />
				)
			)}
			variant={clipboard.copied ? 'light' : 'default'}
			onClick={() => TextCopy(props.id)}
		>
			Copy
		</Button>
	)
}

export default TextCopyButton;
