// zustand
import { create } from 'zustand';

// 文法チェック一覧の定義
type EditListStore = {
	editlist: any;
	setEditList: (editlist: any) => void;
};

const EditListStore = create<EditListStore>((set) => ({
	editlist: [],  //デフォルト
	setEditList: (EditList) => set({ editlist: EditList }),
}));

export default EditListStore;
