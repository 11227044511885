// zustand
import { create } from 'zustand';

// カテゴリ一覧の定義
type CategoryStoreType = {
	categorys: any;
	setCategorys: (Categorys: any) => void;
	categoryid: string;
	setCategoryid: (Categoryid: any) => void;
};

const CategoryStore = create<CategoryStoreType>((set) => ({
	categorys: [],  //デフォルト
	setCategorys: (Categorys) => set({ categorys: Categorys }),
	categoryid: '',  //デフォルト
	setCategoryid: (Categoryid) => set({ categoryid: Categoryid }),
}));

export default CategoryStore;
