// mantine
import { Container, Title, Text, List } from '@mantine/core';

function InfoPage() {
	return (
		<Container>
			<Title mb='lg'>Infomation</Title>
			<Text mb='lg'>
				此処は管理人が運営するR-18指定創作中心のサイトです。<br />
				同性愛要素や物理的暴力、自虐行為、性的描写などの表現が含まれます。<br />
				観覧は全て自己責任でお願いします。全ての作品は管理人による妄想の産物です。原作者様、企業様各位などとは一切関係がございません。<br />
				また、以下の行為並びにこれらに類する行為は禁止させて いただきます。
			</Text>
			<List mx='md'>
				<List.Item>18歳未満（高校生を含む）の方の閲覧</List.Item>
				<List.Item>妄想と現実の区別がつかない方、精神不安定な方の閲覧</List.Item>
				<List.Item>当サイト内にある画像及び文章の無断転載等を行う行為</List.Item>
				<List.Item>ソーシャルオンラインブックマーク及び当サイトから公式サイト等へアクセスする行為</List.Item>
				<List.Item>学校、職場、ネットカフェなど、公共施設や複数の方が使用する端末からアクセス行為</List.Item>
			</List>
		</Container>
	)
}

export default InfoPage;
