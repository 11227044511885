import { MantineColorsTuple } from '@mantine/core';

export const MyColortheme = {
	bluegray: [
		"#f3f3fe",
		"#e4e6ed",
		"#c8cad3",
		"#a9adb9",
		"#9093a4",
		"#808496",
		"#767c91",
		"#656a7e",
		"#585e72",
		"#4a5167"
	] as MantineColorsTuple,
	orange_org: [
		"#fff0e4",
		"#ffe0cf",
		"#fac0a1",
		"#f69e6e",
		"#f28043",
		"#f06d27",
		"#f06418",
		"#d6530c",
		"#bf4906",
		"#a73c00"
	] as MantineColorsTuple,
	deeporange: [
		"#fff4e2",
		"#ffe9cc",
		"#ffd09c",
		"#fdb766",
		"#fca13a",
		"#fb931d",
		"#fc8c0c",
		"#e17900",
		"#c86a00",
		"#ae5a00"
	] as MantineColorsTuple,
	brightgreen: [
		"#effee7",
		"#e0f8d4",
		"#c2efab",
		"#a2e67e",
		"#87de57",
		"#75d940",
		"#6bd731",
		"#59be23",
		"#4da91b",
		"#3d920c"
	] as MantineColorsTuple,
	green_org: [
		"#e5feee",
		"#d2f9e0",
		"#a8f1c0",
		"#7aea9f",
		"#53e383",
		"#3bdf70",
		"#2bdd66",
		"#1ac455",
		"#0caf49",
		"#00963c"
	] as MantineColorsTuple,
	lightblue: [
		"#e0fbff",
		"#cbf2ff",
		"#9ae2ff",
		"#64d2ff",
		"#3cc5fe",
		"#23bcfe",
		"#09b8ff",
		"#00a1e4",
		"#0090cd",
		"#007cb5"
	] as MantineColorsTuple,
	skyblue: [
		"#e1f9ff",
		"#ccedff",
		"#9ad7ff",
		"#64c1ff",
		"#3baefe",
		"#20a2fe",
		"#099cff",
		"#0088e4",
		"#0078cd",
		"#0069b6"
	] as MantineColorsTuple,
	paleblue: [
		"#eef3ff",
		"#dce4f5",
		"#b9c7e2",
		"#94a8d0",
		"#748dc1",
		"#5f7cb8",
		"#5474b4",
		"#44639f",
		"#39588f",
		"#2d4b81"
	] as MantineColorsTuple,
	nrightblue: [
		"#e5f4ff",
		"#cde2ff",
		"#9bc2ff",
		"#64a0ff",
		"#3984fe",
		"#1d72fe",
		"#0969ff",
		"#0058e4",
		"#004ecc",
		"#0043b5"
	] as MantineColorsTuple,
	deepblue: [
		"#ebefff",
		"#d5dafc",
		"#a9b1f1",
		"#7b87e9",
		"#5362e1",
		"#3a4bdd",
		"#2d3fdc",
		"#1f32c4",
		"#182cb0",
		"#0b259c"
	] as MantineColorsTuple,
	paleindigo: [
		"#eef3ff",
		"#dee2f2",
		"#bdc2de",
		"#98a0ca",
		"#7a84ba",
		"#6672b0",
		"#5c68ac",
		"#4c5897",
		"#424e88",
		"#364379"
	] as MantineColorsTuple,
	purple: [
		"#f3edff",
		"#e0d7fa",
		"#beabf0",
		"#9a7ce6",
		"#7c56de",
		"#683dd9",
		"#5f2fd8",
		"#4f23c0",
		"#451eac",
		"#3a1899"
	] as MantineColorsTuple,
	palepurple: [
		"#f2f0ff",
		"#e0dff2",
		"#bfbdde",
		"#9b98ca",
		"#7d79ba",
		"#6a65b0",
		"#605bac",
		"#504c97",
		"#464388",
		"#3b3979"
	] as MantineColorsTuple,
	violet_org: [
		"#f6ecff",
		"#e7d6fb",
		"#caabf1",
		"#ac7ce8",
		"#9354e0",
		"#833cdb",
		"#7b2eda",
		"#6921c2",
		"#5d1cae",
		"#501599"
	] as MantineColorsTuple,
	paleviolet: [
		"#f6eeff",
		"#e7daf7",
		"#cab1ea",
		"#ad86dd",
		"#9562d2",
		"#854bcb",
		"#7d3ec9",
		"#6b31b2",
		"#5f2aa0",
		"#52228d"
	] as MantineColorsTuple,
	darkpink: [
		"#faedff",
		"#edd9f7",
		"#d8b1ea",
		"#c286dd",
		"#ae62d2",
		"#a24bcb",
		"#9e3fc9",
		"#8931b2",
		"#7b2aa0",
		"#6b218d"
	] as MantineColorsTuple,
	brightpink: [
		"#ffebff",
		"#f5d5fc",
		"#e6a9f3",
		"#d779eb",
		"#cb51e4",
		"#c437e0",
		"#c029df",
		"#a91cc6",
		"#9715b1",
		"#840a9c"
	] as MantineColorsTuple,
	pink_org: [
		"#ffe9ff",
		"#fed1fd",
		"#faa1f7",
		"#f66ef1",
		"#f243eb",
		"#f028e8",
		"#f018e8",
		"#d609cf",
		"#bf00b9",
		"#a700a1"
	] as MantineColorsTuple,
	pink_org2: [
		"#ffebf3",
		"#fad6e1",
		"#efacc1",
		"#e47f9f",
		"#dc5982",
		"#d7416f",
		"#d53466",
		"#bd2656",
		"#aa1d4c",
		"#961241"
	] as MantineColorsTuple,
	magenta: [
		"#ffe9f6",
		"#ffd1e6",
		"#faa1c9",
		"#f66eab",
		"#f24391",
		"#f02881",
		"#f01879",
		"#d60867",
		"#c0005c",
		"#a9004f"
	] as MantineColorsTuple,
	brightred: [
		"#ffe9f1",
		"#ffd1e0",
		"#faa1bd",
		"#f66e99",
		"#f2437a",
		"#f02866",
		"#f0185c",
		"#d6094d",
		"#c00043",
		"#a90039"
	] as MantineColorsTuple,
	palered: [
		"#ffeaf3",
		"#fdd4e1",
		"#f4a7bf",
		"#ec779c",
		"#e64f7e",
		"#e3356b",
		"#e22762",
		"#c91a52",
		"#b41149",
		"#9f003e"
	] as MantineColorsTuple,
	deepred: [
		"#ffeaec",
		"#fdd4d6",
		"#f4a7ac",
		"#ec777e",
		"#e64f57",
		"#e3353f",
		"#e22732",
		"#c91a25",
		"#b31220",
		"#9e0419"
	] as MantineColorsTuple,
	red_org: [
		"#ffe9e9",
		"#ffd1d1",
		"#fba0a1",
		"#f76d6d",
		"#f34141",
		"#f22625",
		"#f21616",
		"#d8070b",
		"#c10008",
		"#a90003"
	] as MantineColorsTuple,
	};
