// mantine
import { useState } from 'react';
// mantine
import { Box, Grid, CopyButton, Tabs, Button, Group, Textarea, TextInput, Checkbox } from '@mantine/core';
// アイコン
import { IconExternalLink, IconCopy, IconTextWrap, IconTextPlus, IconListCheck, IconMessage } from '@tabler/icons-react';

// ページロード
import NewPage from '../diff/NewPage';
import OldPage from '../diff/OldPage';

function DiffPage() {

	// テキストステータス
	const [newtexts, setNewTexts] = useState('');
	const [oldtexts, setOldTexts] = useState('');
	const [newid, setNewID] = useState('');
	const [oldid, setOldID] = useState('');
	const [newlabels, setNewLabels] = useState('No Title');
	const [oldlabels, setOldLabels] = useState('No Title');

	return (
		<Grid gutter="xl" grow>
			{/* フォーム */}
			<Grid.Col span={12}>
			<form method='POST' id='difff' name='difff' action='https://difff.jp/' target="_blank">
					<Group gap="xs" grow mb='lg'>
						<Box>
							<Textarea id='sequenceA' name="sequenceA" mb='lg' label={newlabels} placeholder="new text" minRows={5} defaultValue={newtexts} />
							<CopyButton value={newtexts}>
								{({ copied, copy }) => (
									<Button
										fullWidth
										color={copied ? '' : 'gray'}
										leftSection={<IconCopy size={14} />}
										variant='light'
										onClick={copy}
										>
										NEW copy
									</Button>
								)}
							</CopyButton>
						</Box>
						<Box>
							<Textarea id='sequenceB' name="sequenceB" mb='lg' label={oldlabels} placeholder="old text" minRows={5} defaultValue={oldtexts} />
							<CopyButton value={oldtexts}>
							{({ copied, copy }) => (
									<Button
										fullWidth
										color={copied ? '' : 'gray'}
										leftSection={<IconCopy size={14} />}
										variant='light'
										onClick={copy}
										>
										OLD copy
									</Button>
								)}
							</CopyButton>
						</Box>
					</Group>
					{/* ボタン */}
					<Button
						fullWidth
						leftSection={<IconExternalLink size={14} />}
						type="submit"
						my='xl'
					>
						比較
					</Button>
				</form>
			</Grid.Col>
			{/* 新旧リストタブ */}
			<Grid.Col span={12}>
				<Tabs defaultValue="NEW">
					<Tabs.List grow mb='xl'>
						<Tabs.Tab value="NEW" leftSection={<IconTextPlus size={14} />}>
							NEW
						</Tabs.Tab>
						<Tabs.Tab value="OLD" leftSection={<IconTextWrap size={14} />}>
							OLD
						</Tabs.Tab>
						<Tabs.Tab value="FORM" leftSection={<IconMessage size={14} />}>
							FORM
						</Tabs.Tab>
					</Tabs.List>
					<Tabs.Panel value="NEW">
						<NewPage setIDfun={setNewID} setLabelfun={setNewLabels} setTextfun={setNewTexts} />
					</Tabs.Panel>
					<Tabs.Panel value="OLD">
						<OldPage setIDfun={setOldID} setLabelfun={setOldLabels} setTextfun={setOldTexts} />
					</Tabs.Panel>
					<Tabs.Panel value="FORM">
						<form id="ModalFormcheak" method="post" target="sendPhoto" action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScf_muHkJIClo6OjF4IoD29rzICkSayCiLc-9UPfL84dWFu2A/formResponse">
							<Checkbox.Group mb={'lg'} label="確認内容" description="差分内容">
								<Group mt="xs">
									<Checkbox name='entry.1066327700' label="新側差分あり" value="新側差分あり" />
									<Checkbox name='entry.1066327700' label="旧側差分あり" value="旧側差分あり" />
									<Checkbox name='entry.1066327700' label="調査が必要" value="調査が必要" />
								</Group>
							</Checkbox.Group>
							<TextInput mb={'lg'} label='ID' description="New ID" placeholder="Not ID" name='entry.1806474709' defaultValue={newid} />
							<TextInput mb={'lg'} label='Title' description="New Title" placeholder="Not Title" name='entry.913444964' value={newlabels} />
							<TextInput mb={'lg'} label='ID' description="Old ID" placeholder="Not ID" name='entry.283099244' defaultValue={oldid} />
							<TextInput mb={'lg'} label='Title' description="Old Title" placeholder="Not Title" name='entry.699665288' value={oldlabels} />
							<Button
							fullWidth
							leftSection={<IconListCheck size={14} />}
							type="submit"
							my='xl'
							>
								保存
							</Button>
						</form>
						<iframe name="sendPhoto" width={0} height={0}></iframe>
					</Tabs.Panel>
				</Tabs>
			</Grid.Col>
		</Grid>
	)
}

export default DiffPage;
