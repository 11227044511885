// mantine
import { Switch, Group, Text } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
// アイコン
import { IconStarFilled } from '@tabler/icons-react';

// カラーテーマ名(リスト)
const Themes = [
	// デフォルトテーマ
	// { id:'dark', name:'dark'},
	// { id:'gray', name:'gray'},
	{ id:'red', name:'red(デフォルト)'},
	{ id:'pink', name:'pink(デフォルト)'},
	{ id:'grape', name:'grape'},
	{ id:'violet', name:'violet(デフォルト)'},
	{ id:'indigo', name:'indigo'},
	{ id:'blue', name:'blue'},
	{ id:'cyan', name:'cyan'},
	{ id:'teal', name:'teal'},
	{ id:'green', name:'green'},
	{ id:'lime', name:'lime'},
	{ id:'yellow', name:'yellow'},
	{ id:'orange', name:'orange(デフォルト)'},
	// オリジナルテーマ(colortheme)
	{ id:'bluegray', name:'bluegray'},
	{ id:'orange_org', name:'orange(オリジナル)'},
	{ id:'deeporange', name:'deeporange'},
	{ id:'brightgreen', name:'brightgreen'},
	{ id:'green_org', name:'green(オリジナル)'},
	{ id:'lightblue', name:'lightblue'},
	{ id:'skyblue', name:'skyblue'},
	{ id:'paleblue', name:'paleblue'},
	{ id:'nrightblue', name:'nrightblue'},
	{ id:'deepblue', name:'deepblue'},
	{ id:'paleindigo', name:'paleindigo'},
	{ id:'purple', name:'purple'},
	{ id:'palepurple', name:'palepurple'},
	{ id:'violet_org', name:'violet(オリジナル)'},
	{ id:'paleviolet', name:'paleviolet'},
	{ id:'darkpink', name:'darkpink'},
	{ id:'brightpink', name:'brightpink'},
	{ id:'pink_org', name:'pink(オリジナル1)'},
	{ id:'pink_org2', name:'pink(オリジナル2)'},
	{ id:'magenta', name:'magenta'},
	{ id:'brightred', name:'brightred'},
	{ id:'palered', name:'palered'},
	{ id:'deepred', name:'deepred'},
	{ id:'red_org', name:'red(オリジナル)'},
];

function PrimaryColorSwitch() {
	// ローカルストレージカラー
	const [themevalue, setThemeValue, removeThemeValue] = useLocalStorage({
		key: 'color',
		defaultValue: 'cyan',
	});

	// カラースイッチ生成
	function ColorSwich(id: string, name: string) {
		const label = <>
				<Text span={true} c={id} pr={4}><IconStarFilled size={12} /></Text>
				<Text span={true}>{name}</Text>
			</>
		return (
			<Switch
				key={'Switch_' + id}
				color={id}
				value={id}
				label={label}
				size="md"
				checked={themevalue === id}
				onChange={() => setThemeValue(id)}
			/>
		)
	}

	return (
		<Group gap="lg" mx='lg'>
			{Themes.map((item: any) => (
				ColorSwich(item.id, item.name)
			))}
		</Group>
	)
}

export default PrimaryColorSwitch;
