import { useState, useEffect } from 'react';
// ルート設定
import { Link } from "react-router-dom";
// mantine
import { NavLink } from '@mantine/core';
// アイコン
import { IconBookmark, IconList } from '@tabler/icons-react';
// CSSスタイル
import './navmenu.css';

// メニュー開閉のロード
import MenuStore from '../database/MenuStore';

// チャプター・タイトルストアのロード
import ChapterStore from '../database/ChapterStore';
import TitleStore from '../database/TitleStore';

function NavChapter() {
	// 各ストアの読み出し
	const chapters = ChapterStore((state) => state.chapters)
	const title = TitleStore((state) => state.title)
	const setMobileMenu = MenuStore((state) => state.setMobileMenu)

	// メニュー作成
	const menulist = chapters.map((item: any) => (
		<NavLink
			key={'chapters_' + item.id}
			active={item.id === title.chapterid}
			className='menuborder'
			label={item.name}
			description={item.memo}   //説明文
			leftSection={<IconBookmark size='1rem' stroke={1.5} />}
			component={Link}
			to={`/novel/${title.novelid}/${item.id}`}
			onClick={() => (setMobileMenu(false))}
		/>
	));

	return (
		<>
			{(chapters.length === 0 || chapters === undefined || Object.keys(title).length === 0 || title === undefined) ? '' :
				<NavLink
					key='navchapterslist'
					label={title.novelname}
					description={'chapter'}   //説明文
					leftSection={<IconList size="1rem" stroke={1.5} />}
				>
					{menulist}
				</NavLink>
			}
		</>
	)
}

export default NavChapter;
