// mantine
import { Affix, Transition, Button } from '@mantine/core';
import { useWindowScroll, useMediaQuery } from '@mantine/hooks';
// アイコン
import { IconArrowUp } from '@tabler/icons-react';

function ScrollTotopButton() {
	// スクロール用フック
	const [scroll, scrollTo] = useWindowScroll();

	// スマホ判定(スマホのみ表示)
	const isMobile = useMediaQuery(`(min-width: 36em)`)
	const size = !isMobile ? 30 : 40

	return (
		<Affix position={{ bottom: size, right: size }} zIndex={150}>
			<Transition transition="slide-up" mounted={scroll.y > 0}>
				{(transitionStyles) => (
					<Button
						variant="light"
						color="gray"
						leftSection={<IconArrowUp size="1.2rem" stroke={1.5} />}
						onClick={() => scrollTo({ y: 0 })}
					>
						top
					</Button>
				)}
			</Transition>
		</Affix>
	)
}

export default ScrollTotopButton;
