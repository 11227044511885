import React from 'react';
import ReactDOM from 'react-dom/client';

// URL遷移
import { BrowserRouter } from "react-router-dom";
// mantine
import '@mantine/core/styles.css';

// App画面ロード
import App from './App';


// 画面rootを埋め込み
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
