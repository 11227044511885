// zustand
import { create } from 'zustand';

// カテゴリ一覧の定義
type CharacterStore = {
	categorycharacters: any;
	setCategoryCharacter: (categorycharacter: any) => void;
	characters: any;
	setCharacter: (character: any) => void;
};

const CharacterStore = create<CharacterStore>((set) => ({
	categorycharacters: [],  //デフォルト
	setCategoryCharacter: (categorycharacter) => set({ categorycharacters: categorycharacter }),
	characters: [],  //デフォルト
	setCharacter: (Character) => set({ characters: Character }),
}));

export default CharacterStore;
