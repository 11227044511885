// mantine
import { Affix, Button } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

// アイコン
import { IconMenuDeep, IconX } from '@tabler/icons-react';

// メニュー開閉のロード
import MenuStore from '../database/MenuStore';

function MenuSwichButton() {
	// 各ストアの読み出し
	const { mobileopen, setMobileMenu, desktopopen, setDesktopMenu } = MenuStore();

	// スマホ判定(スマホのみ表示)
	const isMobile = useMediaQuery(`(min-width: 36em)`)

	// サイズ
	const sm = useMediaQuery(`(min-width: 48em)`)
	const md = useMediaQuery(`(min-width: 62em)`)
	const lg = useMediaQuery(`(min-width: 75em)`)

	// サイズ判定
	const top = !isMobile ? 30 : 20
	const flg = !isMobile ? mobileopen : desktopopen
	const Buttonicon = flg ? <IconX size="1.2rem" stroke={1.5} /> : <IconMenuDeep size="1.2rem" stroke={1.5} />

	// ボタン位置制御(左)
	const Buttonleft = () => {
		if (!isMobile) {
		// スマホの場合
		return 'auto'
		}else if(!flg) {
		// PCで閉じている場合
		return 10
		}else if(lg) {
		// PCで開いている場合
		return 420
		} else if(md) {
			return 352
		} else if(sm) {
			return 250
		} else {
			return 10
		}
	}
	// ボタン位置制御(右)
	const Buttonright = () => {
		if (!isMobile && flg) {
			return 40
		} else if(!isMobile) {
			return 20
		} else {
			return 'auto'
		}
	}

	return (
		<Affix position={{ top: top, left: Buttonleft(), right: Buttonright() }} zIndex={300}>
			<Button
				variant="light"
				color="gray"
				leftSection={Buttonicon}
				onClick={() => {
					if (!isMobile) {
						setMobileMenu(!flg)
					} else {
						setDesktopMenu(!flg)
					}
				}}
			>
				Menu
			</Button>
		</Affix>
	)
}

export default MenuSwichButton;
