// ルート設定
import { Routes, Route } from "react-router-dom";

// 画面のロード
import InfoPage from './InfoPage';
import NovelPage from './NovelPage';
import TextPage from './TextPage';
import DataPage from './DataPage';
import DiffPage from './DiffPage';
import SearchPage from './SearchPage';
import NotFoundPage from './NotFoundPage';

function MainTemplate() {
	return (
		<Routes>
			{/* Top(Infomation)画面 */}
			<Route path={`/`} element={<InfoPage />} />
			{/* ノベル画面 */}
			<Route path={`/novel/:novelId/:chapterId`} element={<NovelPage />} />
			{/* 本文テキスト画面 */}
			<Route path={`/text/:textId`} element={<TextPage />} />
			{/* データベース画面 */}
			<Route path={`/data`} element={<DataPage />} />
			{/* テキスト比較画面 */}
			<Route path={`/diff`} element={<DiffPage />} />
			{/* 検索画面 */}
			<Route path={`/search`} element={<SearchPage />} />
			{/* Not foundページ */}
			<Route path={`*`} element={<NotFoundPage />} />
		</Routes>
	)
}

export default MainTemplate;
