// ページロード
import TextHeader from '../header/TextHeader';
import NovelText from '../text/NovelText';

function TextPage() {
	return (
		<>
			<TextHeader />
			<NovelText />
		</>
	)
}

export default TextPage;
