// zustand
import { create } from 'zustand';

// カテゴリ一覧の定義
type TitleStoreType = {
	titles: any;
	setTitles: (Titles: any) => void;
	title: any;
	setTitle: (Title: any) => void;
};

const TitleStore = create<TitleStoreType>((set) => ({
	titles: [],  //デフォルト
	setTitles: (Titles) => set({ titles: Titles }),
	title: {},  //デフォルト
	setTitle: (Title) => set({ title: Title }),
}));

export default TitleStore;
