// zustand
import { create } from 'zustand';

// カテゴリ一覧の定義
type NovelStoreType = {
	novels: any;
	setNovels: (Novels: any) => void;
	novelid: string;
	setNovelid: (Novelid: any) => void;
};

const NovelStore = create<NovelStoreType>((set) => ({
	novels: [],  //デフォルト
	setNovels: (Novels) => set({ novels: Novels }),
	novelid: '',  //デフォルト
	setNovelid: (Novelid) => set({ novelid: Novelid }),
}));

export default NovelStore;
