import { useState, useMemo } from 'react';
// mantine
import { Table, Checkbox, Group } from '@mantine/core';
import { readLocalStorageValue } from '@mantine/hooks';

// コピーボタンのロード
import TextCopyButton from './TextCopyButton'

function TitleLists(props: any) {
	// リスト内容
	const titlelists = props.lists
	// 選択中カラムリスト
	const [selectedRows, setSelectedRows] = useState<number[]>([]);
	// キーワードカラー値
	const KeyColor = readLocalStorageValue({ key: 'color', defaultValue: 'cyan' });

	// リストの行を生成
	const listrows = titlelists.map((item: any) => (
		<Table.Tr
			key={item.id}
			bg={selectedRows.includes(item.id) ? `var(--mantine-color-${KeyColor}-light)` : undefined}
		>
		<Table.Td>
			<Checkbox
				aria-label="Select row"
				checked={selectedRows.includes(item.id)}
				onChange={(event) =>
					setSelectedRows(
						event.currentTarget.checked
						? [...selectedRows, item.id]
						: selectedRows.filter((position) => position !== item.id)
					)
				}
			/>
		</Table.Td>
		<Table.Td>
			<Group gap="xs">
				<TextCopyButton id={item.id} title={item.title} DBtype={props.DBtype} setIDfun={props.setIDfun} setLabelfun={props.setLabelfun} setTextfun={props.setTextfun} />
			</Group>
		</Table.Td>
		<Table.Td>{item.title}</Table.Td>
		<Table.Td>{item.chaptername}</Table.Td>
		</Table.Tr>
	));

	return (
		<Table.ScrollContainer minWidth={800}>
			<Table striped>
				<Table.Thead>
					<Table.Tr>
					<Table.Th />
					<Table.Th />
					<Table.Th>title</Table.Th>
					<Table.Th>チャプター名</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{listrows}
				</Table.Tbody>
			</Table>
			</Table.ScrollContainer>
	)
}

export default TitleLists;
