import { useState, useEffect } from 'react';
// ルート設定
import { useParams } from 'react-router-dom';
// mantine
import { Skeleton } from '@mantine/core';

// ページ画面ロード
import TextPageNation from './TextPageNation';
import TextEdit from './TextEdit';

// APIのURL
const envApiUrl = process.env.REACT_APP_API_URL;

function NovelText() {
	// URLからtextIdを取得
	const { textId } = useParams<string>();

	// 各種定義保管ステート
	const [ titleid, setTitleid ] = useState('');
	const [ maintext, setMainText ] = useState('');

	// ローディング状態を管理するステート
	const [loading, setLoading] = useState<boolean>(true);

	// タイトルidを保存
	useEffect(() => {
		function saveTitle() {
			if (textId !== undefined && textId !== titleid && titleid !== undefined ) {
				setTitleid(textId)
				// ローディング画面開始
				setLoading(true)
			}
		}
		saveTitle();
	}, [textId]); // textIdが変更されたときに再実行されます

	// テキスト本文の取得
	useEffect(() => {
		// データを取得する非同期関数を定義します
		async function fetchData() {
			if (titleid === undefined || titleid === '') return
			// APIURLの生成
			const apiURL = `${envApiUrl}/gettext.php?id=${titleid}`
			try {
				// APIからデータを取得します
				const response = await fetch(apiURL);
				// レスポンスがOKでない場合はエラーを投げます
				if (!response.ok) {
				throw new Error('APIからデータを取得できませんでした.');
				}
				// データをJSON形式で取得し、親コンポーネントに渡します
				const data = await response.json();
				if (data.result.length === 0) {
					setMainText('');
				} else {
					setMainText(data.result[0].content);
				}
				setLoading(false)  // ローディング画面終了
			} catch (error) {
				// エラーが発生した場合はコンソールにログを出力し、親コンポーネントにnullを返します
				console.error('There was a problem with fetching the data:', error);
				// エラー処理を行う場合はここに記述します
			}
		}
		// データを取得する関数を実行します
		fetchData();
	}, [titleid]); // titleidが変更されたときに再実行されます

	return (
		<>
			{/* ページネーション */}
			{loading ? (
				<><Skeleton height={30} mb={30} radius="xl" /></>
			) : 
				<><TextPageNation /></>
			}
			{/* 本文 */}
			{loading ? (
				<>
					{[...Array(15)].map((_, index) => (
						<Skeleton key={index} height={12} my={12} radius="xl" />
					))}
				</>
			) : (
					<TextEdit text={maintext}/>
				)}
			{/* ページネーション */}
			{loading ? (
				<><Skeleton height={30} mb={30} radius="xl" /></>
			) : 
				<><TextPageNation /></>
			}
		</>
	)
}

export default NovelText;
