// mantine

// ページロード
import NovelHeader from '../header/NovelHeader';
import NovelTextList from '../novel/NovelTextList';

function NovelPage() {

	return (
		<>
			<NovelHeader />
			<NovelTextList />
		</>
	)
}

export default NovelPage;
