import { useMemo, useState, useEffect } from 'react';
// ルート設定
import { useParams, Link, useNavigate } from 'react-router-dom';
// mantine
import { Skeleton, Divider, Select, Title, Box, Text, Spoiler, Group, Badge, Flex, Button, Grid, Menu, Pagination } from '@mantine/core';
import { useMediaQuery, useWindowScroll, useSessionStorage } from '@mantine/hooks';
import { IconMessage, IconCategory2, IconTags, IconUser, IconFile } from '@tabler/icons-react';

// ノベル・チャプターストアのロード
import NovelStore from '../database/NovelStore';
import ChapterStore from '../database/ChapterStore';
// タイトルストアのロード
import TitleStore from '../database/TitleStore';

// コピーボタンのロード
import TextCopyButton from '../text/TextCopyButton'

// APIのURL
const envApiUrl = process.env.REACT_APP_API_URL;
// 表示最大件数
const maxPageCount = 10;
const maxTextHeight = 132;

function NovelTextList() {
	// URLからnovelIdを取得
	const { chapterId } = useParams<string>();

	// 各ストアの読み出し
	const chapters = ChapterStore((state) => state.chapters)
	const novelid = NovelStore((state) => state.novelid)
	const { titles, setTitles } = TitleStore();

	// URL変更用
	let navigate = useNavigate();

	// ローディング状態を管理するステート
	const [loading, setLoading] = useState<boolean>(true);

	// スクロール用フック
	const [scroll, scrollTo] = useWindowScroll();

	// ページ数ステート
	const [MaxPage, setMaxPage] = useState(0);
	// ページストレージ
	const [ActivePage, setActivePage] = useSessionStorage({
		key: 'ActivePage',
		defaultValue: 1,
	})

	// スマホ判定(スマホのみ表示)
	const isMobile = useMediaQuery(`(min-width: 36em)`)

	// セレクトボックスリストの生成
	const selectOptions = [
		{ value: 'all', label: 'All' }, // "all" を追加
		...chapters
			.map((item: any) => ({ value: item.id, label: item.name }))
	];

	// 件数の取得
	useEffect(() => {
		// データを取得する非同期関数を定義します
		async function fetchData() {
			// novelIdとchapterIdが初期値の場合はスキップ
			if (novelid === '' || novelid === undefined || chapterId === '' || chapterId === undefined) return
			// ローディング画面開始
			setLoading(true)
			setMaxPage(0);  // 初期値にする
			// chapterIdがallか、idかで検索URLを設定
			const type = chapterId === 'all' ? 'novelid' : 'chapterid'
			const id = type === 'novelid' ? novelid : chapterId
			const apiurl = `${envApiUrl}/search.php?type=SELECTCOUNT&table=fulltitle&column=${type}&word=${id}`
			try {
				// APIからデータを取得します
				const response = await fetch(apiurl);
				// レスポンスがOKでない場合はエラーを投げます
				if (!response.ok) {
					// エラー処理を行う場合はここに記述します
					setLoading(false)  // ローディング画面終了
					throw new Error('APIからデータを取得できませんでした.');
				}
				// データをJSON形式で取得し、親コンポーネントに渡します
				const data = await response.json();
				const counts = data.result[0].count;
				// 件数が0の場合は初期値にしてローディング画面終了
				if (counts === 0) {
					setLoading(false)  // ローディング画面終了
				} else {
					setMaxPage(maxCount(counts))
				}
			} catch (error) {
				// エラーが発生した場合はコンソールにログを出力し、親コンポーネントにnullを返します
				console.error('There was a problem with fetching the data:', error);
				// エラー処理を行う場合はここに記述します
				setLoading(false)  // ローディング画面終了
			}
		}
		// ページ数カウント
		function maxCount(count: any) {
			if ((count % maxPageCount) === 0) {
				return Math.floor(count / maxPageCount)
			} else {
				return Math.floor(count / maxPageCount) + 1
			}
		}
		// データを取得する関数を実行します
		fetchData();
	}, [novelid,chapterId]); // novelid,chapterIdが変更されたときに再実行されます

	// タイトル情報取得
	useEffect(() => {
		// データを取得する非同期関数を定義します
		async function fetchData() {
			// novelIdとchapterIdとActivePageが初期値の場合はスキップ
			if (novelid === '' || novelid === undefined || chapterId === '' || chapterId === undefined || ActivePage === 0 || MaxPage === 0) return
			// ローディング画面開始
			setLoading(true)
			// topへ戻る
			scrollTo({ y: 0 });
			// chapterIdがallか、idかで検索URLを設定
			const startline = (ActivePage - 1) * maxPageCount
			const type = chapterId === 'all' ? 'novelid' : 'chapterid'
			const id = type === 'novelid' ? novelid : chapterId
			const apiurl = `${envApiUrl}/search.php?type=SELECTCOLUMN&table=fulltitle&column=${type}&word=${id}&line=${maxPageCount}&start=${startline}`
			try {
				// APIからデータを取得します
				const response = await fetch(apiurl);
				// レスポンスがOKでない場合はエラーを投げます
				if (!response.ok) {
					setTitles([]);  // 空にする
					setLoading(false)  // ローディング画面終了
					throw new Error('APIからデータを取得できませんでした.');
				}
				// データをJSON形式で取得し、親コンポーネントに渡します
				const data = await response.json();
				setTitles(data.result);
				setLoading(false)  // ローディング画面終了
			} catch (error) {
				// エラーが発生した場合はコンソールにログを出力し、親コンポーネントにnullを返します
				console.error('There was a problem with fetching the data:', error);
				// エラー処理を行う場合はここに記述します
				setTitles([]);  // 空にする
				setLoading(false)  // ローディング画面終了
			}
		}
		// データを取得する関数を実行します
		fetchData();
}, [MaxPage,ActivePage]); // ページが変更されたときに再実行されます


		// タイトルリスト表示関数
		function TitleItems(item: any, index: number) {
			// タイトルリスト生成
			return (
				<Box key={'boxcard_' + item.id} mb={{ base: 20, sm: 60, lg: 80 }}>
					{/* ブロックヘッター */}
					<Grid justify="space-between" align="center">
						{/* タイトル */}
						<Grid.Col span={{ base: 12, lg: 'auto' }}>
							<Title order={4}>{item.title}</Title>
						</Grid.Col>
						{/* メニューボタン */}
						<Grid.Col span={{ base: 12, lg: 'content' }}>
							<Group gap="xs" grow={!isMobile}>
								<Menu shadow="md" width={200} withArrow>
									<Menu.Target>
										<Button
										leftSection={<IconCategory2 size={14} />}
										variant="default"
										>
											Menu
										</Button>
									</Menu.Target>
									<Menu.Dropdown>
										<Menu.Label>Application</Menu.Label>
										<Menu.Item leftSection={<IconCategory2 size={14} />} disabled>
										Settings
										</Menu.Item>
										<Menu.Divider />
										<Menu.Item leftSection={<IconTags size={14} />} disabled>
										tags
										</Menu.Item>
										<Menu.Item leftSection={<IconUser size={14} />} disabled>
										character
										</Menu.Item>
									</Menu.Dropdown>
								</Menu>
								{/* コピーボタン */}
								<TextCopyButton id={item.id} />
								{/* テキストページリンクボタン */}
								<Button
									leftSection={<IconFile size={14} />}
									variant="default"
									component={Link}
									to={`/text/${item.id}`}
									onClick={() => scrollTo({ y: 0 })}
								>
									View
								</Button>
							</Group>
						</Grid.Col>
					</Grid>
					{/* テキスト概要 */}
					<Spoiler maxHeight={maxTextHeight} showLabel="More" hideLabel="back">
						<Text py='sm' size="sm" c="dimmed">{item.text}</Text>
					</Spoiler>
					<Divider key={'Divider_' + index} variant="dashed" my='xs' label={'No.' + (((ActivePage - 1) * maxPageCount + index + 1) > 99 ? ((ActivePage - 1) * maxPageCount + index + 1) : (((ActivePage - 1) * maxPageCount + index + 1) > 9 ? ('0' + ((ActivePage - 1) * maxPageCount + index + 1)) : ('00' + (index + 1)) ))} labelPosition='right' />
				</Box>
			)
		}

	// タイトルリストを表示する
	const TitleList = useMemo(() => {
		return () => {
			// 初期値は何もしない
		if (MaxPage === 0 || chapterId === '' || chapterId === undefined) return `チャプターID「${chapterId}」が不正です`
		if (titles.length === 0 || titles === undefined) return 'タイトルリストが取得できません'
		// タイトルリストを取得する
		return titles.map((item: any, index: number) => (
			<Box key={'Box_' + item.id}>
				{index === 0 ?
					(<Divider key={'Divider_' + item.chapterid} my='xs' label={item.chaptername} labelPosition='left' />) :
					(titles[(index - 1)].chapterid !== item.chapterid ?
						(<Divider key={'Divider_' + item.chapterid} my='xs' label={item.chaptername} labelPosition='left' />) :
						''
					)
				}
				{TitleItems(item, index)}
			</Box>
		))
	};
}, [titles]);

	// ページネーションの生成
	const PageNationList = () => {
		// 初期値は何もしない
		if (ActivePage === undefined || MaxPage === undefined) return
		return (
			<Pagination
				key={'Pagination'}
				value={ActivePage}
				// クリックアクション
				onChange={setActivePage}
				// 最大ページ数
				total={MaxPage}
				// 最初/最後の固定数
				boundaries={!isMobile ? 1 : 3}
				// 次/前の固定数
				siblings={!isMobile ? 1 : 5}
				// 次のボタン→常時追加
				withControls={!isMobile ? false : true}
				withEdges
			/>
		)
	}

	// チャプターセレクトボックスの変更
	function UrlChange(value :any) {
		navigate(`/novel/${novelid}/${value}`)
		setActivePage(1)
	}

	return (
		<>
			{/* チャプターセレクトボックスの表示 */}
			<Select
				mb={40}
				data={selectOptions}
				value={chapterId}
				allowDeselect={false}
				onChange={(value: any) => { UrlChange(value) }}
			/>
			{/* ロード画面 */}
			{loading ? (
				<>
					{[...Array(15)].map((_, index) => (
						<Skeleton key={index} height={12} mt={12} radius="xl" />
					))}
				</>
			) : (
				<>
					{/* タイトルリストの表示 */}
					<TitleList />
					{/* ページネーション */}
					{MaxPage > 1 ? PageNationList() : ''}
				</>
			)}
		</>
	)
}

export default NovelTextList;
