// ルート設定
import { Link } from "react-router-dom";
// mantine
import { NavLink } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
// アイコン
import { IconMenu2, IconHome, IconDatabase, IconArrowsDiff, IconHomeSearch } from '@tabler/icons-react';
// CSSスタイル
import './navmenu.css';

// メニュー開閉のロード
import MenuStore from '../database/MenuStore';

// メニューデータ
const menudatas = [
	{ id: 'Infopage',   name: 'Home',   icon: IconHome,       link: '/', memo: 'インフォメーション' },
	{ id: 'datapage',   name: 'Data',   icon: IconDatabase,   link: '/data', memo: 'データリスト' },
	{ id: 'diffpage',   name: 'Diff',   icon: IconArrowsDiff, link: '/diff', memo: 'テキスト比較' },
	{ id: 'searchpage', name: 'Search', icon: IconHomeSearch, link: '/search', memo: '検索' },
]

function NavMenu() {
	// 各ストアの読み出し
	const setMobileMenu = MenuStore((state) => state.setMobileMenu)

	// スクロール用フック
	const [scroll, scrollTo] = useWindowScroll();

	// メニュー作成
	const menulist = menudatas.map((item: any) => (
		<NavLink
			key={item.id}
			className='menuborder'
			label={item.name}
			description={item.memo}   //説明文
			leftSection={<item.icon size='1rem' stroke={1.5} />}
			component={Link}
			to={item.link}
			onClick={() => (setMobileMenu(false),scrollTo({ y: 0 }))}
		/>
	));

	return (
		<>
			<NavLink
				key='navmenulist'
				label='Menu'
				description={'Art you Alice? / AliceGame'}   //説明文
				leftSection={<IconMenu2 size="1rem" stroke={1.5} />}
				defaultOpened    //初期展開
			>
				{menulist}
			</NavLink>
		</>
	)
}

export default NavMenu;
