import { useState, useEffect } from 'react';
// ルート設定
import { useParams } from 'react-router-dom';
// mantine
import { Skeleton, Box, Title, Text } from '@mantine/core';

// カテゴリ・ノベル・チャプターストアのロード
import CategoryStore from '../database/CategoryStore';
import NovelStore from '../database/NovelStore';
import ChapterStore from '../database/ChapterStore';
// タイトルストアのロード
import TitleStore from '../database/TitleStore';
// キャラクターストアのロード
import CharacterStore from '../database/CharacterStore';

// APIのURL
const envApiUrl = process.env.REACT_APP_API_URL;

function TextHeader() {
	// URLからtextIdを取得
	const { textId } = useParams<string>();

	// 各ストアの読み出し
	const { categoryid, setCategoryid } = CategoryStore();
	const { novelid, setNovelid } = NovelStore();
	const setChapters = ChapterStore((state) => state.setChapters)
	const { title, setTitle } = TitleStore();
	const setCategoryCharacter = CharacterStore((state) => state.setCategoryCharacter)
	const setCharacter = CharacterStore((state) => state.setCharacter)

	// ローディング状態を管理するステート
	const [loading, setLoading] = useState<boolean>(true);

	// ページタイトルを取得
	useEffect(() => {
		// データを取得する非同期関数を定義します
		async function fetchData() {
			// 初期値の場合はスキップ
			if (textId === undefined || textId === '') return
			// 変更がない場合はスキップ
			if (title.length === 0) return
			if (textId === title.id) return setLoading(false)  // ローディング画面終了
			// ローディング画面開始
			setLoading(true)
			// APIURLの生成
			const apiURL = `${envApiUrl}/search.php?type=SELECTID&table=fulltitle&id=${textId}`
			try {
				// APIからデータを取得します
				const response = await fetch(apiURL);
				// レスポンスがOKでない場合はエラーを投げます
				if (!response.ok) {
				throw new Error('APIからデータを取得できませんでした.');
				}
				// データをJSON形式で取得し、親コンポーネントに渡します
				const data = await response.json();
				if (data.result.length === 0) {
					setTitle({});
				} else {
					setTitle(data.result[0]);
				}
				setLoading(false)  // ローディング画面終了
			} catch (error) {
				// エラーが発生した場合はコンソールにログを出力し、親コンポーネントにnullを返します
				console.error('There was a problem with fetching the data:', error);
				// エラー処理を行う場合はここに記述します
			}
		}
		// データを取得する関数を実行します
		fetchData();
	}, [textId]); // textIdが変更されたときに再実行されます

	// 今のカテゴリ・ノベル・チャプター情報を保存
	useEffect(() => {
		function savetitle() {
			// データがない場合は何もしない
			if (title === undefined) return
			if (Object.keys(title).length === 0) return
			// カテゴリID保存
			if (categoryid !== title.categoryid) {
				setCategoryid(title.categoryid)
			}
			// ノベルID保存
			if (novelid !== title.novelid) {
				setNovelid(title.novelid)
			}
		}
		savetitle();
	}, [title]); // titleが変更されたときに再実行されます

	// データを取得する非同期関数を定義します
	async function fetchData(table: string, column: string, id: string, setfun: any) {
		// データがない場合は何もしない
		if (title === undefined || novelid === '' || novelid === undefined || id === '' || id === undefined) return
		if (Object.keys(title).length === 0) return
		// APIURLの生成
		const apiURL = `${envApiUrl}/search.php?type=SELECTCOLUMNALL&table=${table}&column=${column}&word=${id}`
		try {
			// APIからデータを取得します
			const response = await fetch(apiURL);
			// レスポンスがOKでない場合はエラーを投げます
			if (!response.ok) {
				throw new Error('APIからデータを取得できませんでした.');
			}
			// データをJSON形式で取得し、親コンポーネントに渡します
			const data = await response.json();
			if (data.result.length === 0) {
				setfun([])
			} else {
				setfun(data.result)
			}
		} catch (error) {
			// エラーが発生した場合はコンソールにログを出力し、親コンポーネントにnullを返します
			console.error('There was a problem with fetching the data:', error);
			// エラー処理を行う場合はここに記述します
			setfun([])
		}
	}

	// キャラクター,チャプター情報取得
	useEffect(() => {
		// データを取得する関数を実行します(チャプター)
		fetchData('chapters','novelid',novelid , setChapters)
		// データを取得する関数を実行します(ノベル別 キャラクター)
		fetchData('characters', 'type', novelid, setCharacter)
	}, [novelid]); // novelidが変更されたときに再実行されます

	// キャラクター情報取得
	useEffect(() => {
		// データを取得する関数を実行します(カテゴリ別 キャラクター)
		fetchData('characters', 'type',categoryid , setCategoryCharacter)
	}, [categoryid]); // categoryidが変更されたときに再実行されます

	// ヘッター表示関数
	function titlehrader() {
		if (loading) {
			return <Skeleton height={30} mb={30} radius="xl" />
		} else if (Object.keys(title).length === 0 ) {
		// title情報が取得できない場合はエラー
		return (
				<Title order={3}>「{textId}」のIDはありません。</Title>
				)
		} else {
			// それ以外は表示
			return (
				<Box mb={40}>
					<Text c='dimmed' fz='sm'>{ title.categoryname } / { title.novelname } / { title.chaptername }</Text>
					<Title>{title.title}</Title>
				</Box>
			)
		}
	}

	return (
		<>
			{titlehrader()}
		</>
	)
}

export default TextHeader;
