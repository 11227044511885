import { useState } from 'react';
// mantine
import { Loader, ActionIcon } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
// アイコン
import { IconCheck, IconCopy } from '@tabler/icons-react';

// APIのURL
const envApiUrl = process.env.REACT_APP_API_URL;

function TextCopyButton(props: any) {
	// テキストコピーステート
	const [copied, setCopied] = useState<boolean>(false);
	const clipboard = useClipboard({ timeout: 2000 });

	// テキストコピー
	const TextCopy = async (id: string, title: string, DBtype: string) => {
		// ローディング画面開始
		setCopied(true)
		try {
			// APIURLの生成
			if (id === '' || id === undefined || id === '--------') return
			if (DBtype != 'new_text' && DBtype != 'old_text') return
			const apiURL = `${envApiUrl}/diffsearch.php?DBtype=${DBtype}&table=texts&type=SELECTID&id=${id}`
			props.setIDfun(id)
			props.setLabelfun(title)
			// APIからテキストを取得するためのリクエストを送信
			const response = await fetch(apiURL);
			if (!response.ok) {
				// エラー内容をクリップボードにコピー
				props.setTextfun(`「${id}」のデータを取得できませんでした`)
				throw new Error('Failed to fetch data');
			}
			const data = await response.json();
			if (data.result.length === 0) {
				// エラー内容をクリップボードにコピー
				props.setTextfun(`「${id}」のデータを取得できませんでした`)
			} else {
				// 取得したテキストをクリップボードにコピー
				props.setTextfun(data.result[0].content)
			}
			setCopied(false)  // ローディング画面終了
		} catch (error) {
			console.error('Error copying text:', error);
			// エラー内容をクリップボードにコピー
			props.setTextfun(`「${id}」のデータを取得できませんでした`)
			setCopied(false)  // ローディング画面終了
		}
	};

	return (
		<ActionIcon
			variant='light'
			onClick={() => TextCopy(props.id, props.title, props.DBtype)}
		>
			{clipboard.copied ? (
				<IconCheck size={14} />
			) : (
				copied ? (
					<Loader size="xs" color="blue" />
				) : (
					<IconCopy size={14} />
				)
			)}
		</ActionIcon >
	)
}

export default TextCopyButton;
