// mantine
import { useState, useEffect } from 'react';
// mantine
import { Grid, Skeleton, Box, ScrollArea, Collapse, Group, NativeSelect, Button, TextInput, Radio, ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

// アイコン
import { IconReload, IconListSearch } from '@tabler/icons-react';

// タイトルリスト
import TitleLists from '../diff/TitleLists';

interface Data {
	id: string;
	chaptername: string;
	title: string;
}

// APIのURL
const envApiUrl = process.env.REACT_APP_API_URL;

function OldPage(props: any) {
	// ローディング状態を管理するステート
	const [loading, setLoading] = useState<boolean>(false);

	// ステータス
	const [categoryid, setCategoryID] = useState('--------');
	const [novelid, setNovelID] = useState('--------');
	const [categorylists, setCategoryLists] = useState([]);
	const [novellists, setNovelLists] = useState([]);
	const [titlelists, setTitleLists] = useState<Data[]>([]);
	const [searchtype, setSearchType] = useState('ID');
	const [searchText, setSearchText] = useState('');
	const [filteredItems, setFilteredItems] = useState<Data[]>([]);
	const [opened, { toggle }] = useDisclosure(true);


	// 各ストアの読み出し
	useEffect(() => {
		GettitleData('ALL','category')
		GettitleData('ALL','novel')
	}, []); // 初回だけ実行

	// セレクトボックスリストの生成
	const categoryOptions = [
		{ value: '', label: '--------' }, // デフォルトを追加
		...categorylists
			.map((item: any) => ({ value: item.id, label: item.name }))
	];
	const novelOptions = [
		{ value: '', label: '--------' }, // デフォルトを追加
		...novellists.filter((item: any) =>
			item.categoryid.includes(categoryid)
		).map((item: any) => ({ value: item.id, label: item.name }))
	];

	// タイトルリストをフィルター
	function SearchFilter() {
		if (searchText === '') {
			setFilteredItems(titlelists);
		} else {
			const filtered = titlelists.filter(item => {
				if (searchtype === 'ID') {
					return item.id.includes(searchText);
				} else if (searchtype === 'Title') {
					return item.title.includes(searchText);
				} else if (searchtype === 'Chapter') {
					return item.chaptername.includes(searchText);
				}
				return false;
			});
			setFilteredItems(filtered);
		}
	};

	// データを取得する非同期関数を定義します
	async function GettitleData(type: any,id: any) {
		// 初期値の場合はスキップ
		if (type !== 'SELECTCOLUMNALL' && type !== 'ALL') return
		if (id === '' || id === undefined || id === '--------') return
		// ローディング画面開始
		setLoading(true)
		const word =  type === 'SELECTCOLUMNALL' ? id : ''
		const table = type === 'SELECTCOLUMNALL' ? 'fulltitle' : (id === 'category' ? 'categorys' : 'novels')
		const apiurl = `${envApiUrl}/diffsearch.php?DBtype=old_novel&type=${type}&table=${table}&column=novelid&&word=${word}`
		try {
			// APIからデータを取得します
			const response = await fetch(apiurl);
			// レスポンスがOKでない場合はエラーを投げます
			if (!response.ok) {
				if (type === 'SELECTCOLUMNALL') {  // 空にする
					setTitleLists([]);
				}
				setLoading(false)  // ローディング画面終了
				throw new Error('APIからデータを取得できませんでした.');
			}
			// データをJSON形式で取得し、親コンポーネントに渡します
			const data = await response.json();
			if (type === 'SELECTCOLUMNALL') {  // 空にする
				setTitleLists(data.result);
				setFilteredItems(data.result);
			} else if (id === 'category') {
				setCategoryLists(data.result);
			} else if (id === 'novel') {
				setNovelLists(data.result);
			} else {
			}
			setLoading(false)  // ローディング画面終了
		} catch (error) {
			// エラーが発生した場合はコンソールにログを出力し、親コンポーネントにnullを返します
			console.error('There was a problem with fetching the data:', error);
			// エラー処理を行う場合はここに記述します
			if (type === 'SELECTCOLUMNALL') {  // 空にする
				setTitleLists([]);
			}
			setLoading(false)  // ローディング画面終了
		}
	}

	return (
		<>
			<Button mb={30} fullWidth variant="light" onClick={toggle}>検索</Button>
			<Collapse in={opened}>
				{/* OLD版のリスト画面 */}
				<Box mb={60}>
					{/* カテゴリ */}
					<NativeSelect mb={'lg'}
						label="Category"
						description="old Category"
						data={categoryOptions}
						value={categoryid}
						onChange={(event) => setCategoryID(event.currentTarget.value)}
					/>
					{/* ノベル */}
					<NativeSelect mb={'lg'}
						label="Novel"
						description="old Novel"
						data={novelOptions}
						value={novelid}
						onChange={(event) => setNovelID(event.currentTarget.value)}
					/>
					{/* 一覧表示ボタン */}
					<Button fullWidth
						leftSection={<IconReload size={14} />}
						onClick={() => GettitleData('SELECTCOLUMNALL',novelid)}
					>
						一覧表示
					</Button>
				</Box>
				{/* フィルタ機能 */}
				<Box mb={60}>
					<Grid mb={'lg'}>
						<Grid.Col span="content">
							<Radio.Group
								value={searchtype}
								onChange={setSearchType}
								name="old_check"
								description="Type"
							>
								<Group mt={16}>
									<Radio value="ID" label="ID" />
									<Radio value="Title" label="Title" />
									<Radio value="Chapter" label="Chapter" />
								</Group>
							</Radio.Group>
						</Grid.Col>
						<Grid.Col span="auto">
							<TextInput description="Search" placeholder="Search" name='' onChange={(event) => setSearchText(event.currentTarget.value)} />
						</Grid.Col>
						<Grid.Col span="content">
							<ActionIcon mt={16} h={40} w={40} onClick={() => SearchFilter()}>
								<IconListSearch size={20} />
						</ActionIcon>
						</Grid.Col>
					</Grid>
				</Box>
			</Collapse>
			{/* OLD版タイトルリスト表示 */}
			<Box>
				{loading ? (
					<>
						{[...Array(15)].map((_, index) => (
							<Skeleton key={index} height={12} mt={12} radius="xl" />
						))}
					</>
				) :
					<ScrollArea h={500}>
						<TitleLists lists={filteredItems} DBtype='old_text' setIDfun={props.setIDfun} setLabelfun={props.setLabelfun} setTextfun={props.setTextfun} />
					</ScrollArea>
				}
			</Box>
		</>
	)
}

export default OldPage;
