import { useEffect } from 'react';
// mantine
import { NavLink } from '@mantine/core';
// アイコン
import { IconCheck, IconPalette } from '@tabler/icons-react';
// CSSスタイル
import './navmenu.css';
// データロード
import EditListStore from '../database/EditListStore';

// キーワード登録
const searchDatas = [
	// 行ごと対象
	{name:'会話文', default:true},
	// 一括変換ワード
	{name:'囲い(「」『』【】（）“”《》≪≫)', default:false},
	{name:'区切り文字', default:true},
	{name:'行末と会話文以外の文末', default:false},
	{name:'連続記号', default:false},
	{name:'数字(半角)', default:false},
	{name:'数字(全角)', default:false},
	{name: '漢数字', default:false},
	{name:'英字(半角)', default:false},
	{name:'英字(全角)', default:false},
	{name:'空白(半角)', default:false},
	{name:'空白(全角)', default:false},
	{name: '記号(半角)', default:false},
	{name: '記号(全角)', default:false},
	{name: '英字/数字/ひらがな/カタカナ/漢字以外', default:false},
	{name:'想定外の文字', default:true},
	// 他の検索ワードを追加
	// ----------------------------------------タグとキャラクター蘭の追加要素を考える場所
	{name:'一人称', default:false},
	{name:'二人称', default:false},
	{name:'三人称', default:false},
	{name:'R用語', default:false},
	{name:'タグ', default:true},
	{name:'キャラクター(カテゴリ)', default:true},
	{name:'キャラクター(ノベル)', default:true},
];

function TextEditSwich() {

	// 各種定義保管ステート
	const { editlist, setEditList } = EditListStore();
	useEffect(() => {
		setEditList(searchDatas.filter((item) => item.default).map((item) => item.name))
	}, []);  //デフォルト値を設定

	// nameを追加または削除する関数
	const toggleEdit = (name: string) => {
		// nameがeditに含まれているかチェック
		const index = editlist.indexOf(name);
		if (index === -1) {
			// nameがeditに含まれていない場合、追加する
			setEditList([...editlist, name]);
		} else {
			// nameがeditに含まれている場合、削除する
			const newEdit = [...editlist];
			newEdit.splice(index, 1);
			setEditList(newEdit);
		}
	};

	// メニュー作成
	const menulist = searchDatas.map((item: any, index: number) => (
		<NavLink
			key={'TextEditSwich_' + index}
			active={editlist.indexOf(item.name) === -1 ? false : true}
			className='menuborder'
			label={item.name}
			// description={item.memo}   //説明文
			leftSection={<IconCheck size='1rem' stroke={1.5} />}
			onClick={() => (toggleEdit(item.name))}
		/>
	));

	return (
		<>
				<NavLink
					key='navTextEditSwich'
					label='文法チェック'
					description={'文法チェック'}   //説明文
					leftSection={<IconPalette size="1rem" stroke={1.5} />}
				>
					{menulist}
				</NavLink>
		</>
	)
}

export default TextEditSwich;
