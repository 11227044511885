// mantine
import { AppShell, ScrollArea, rem } from '@mantine/core';

// データロード
import GetCategoryData from '../database/GetCategoryData';
import GetNovelData from '../database/GetNovelData';

// メニュー開閉のロード
import MenuStore from '../database/MenuStore';
import MenuSwichButton from '../navbar/MenuSwichButton';

// Template画面のロード
import NavbarTemplate from './NavbarTemplate';
import MainTemplate from './MainTemplate';
import MainfooterTemplate from './MainfooterTemplate';

// topへ戻る
import ScrollTotopButton from '../component/ScrollTotopButton';

function Template() {
	// 各ストアの読み出し
	const mobileopen = MenuStore((state) => state.mobileopen)
	const desktopopen = MenuStore((state) => state.desktopopen)

	return(
		<AppShell
			padding={{ base: rem('1rem'), sm: 48, md: 48, lg: 52}}
			navbar={{
				width: { base: 100, sm: 240, md: 340, lg: 400 },
				breakpoint: 'sm',
				collapsed: { mobile: !mobileopen, desktop: !desktopopen },
			}}
		>
			{/* ナビ枠 */}
			<AppShell.Navbar component={ScrollArea} zIndex={200}>
				<GetCategoryData />
				<GetNovelData />
				<NavbarTemplate />
			</AppShell.Navbar>
			{/* メイン枠 */}
			<AppShell.Main mt={{base: 36}} pb={100}>
				<MainTemplate />
				<MainfooterTemplate />
			</AppShell.Main>
			{/* topへ戻るボタン */}
			<ScrollTotopButton />
			{/* メニュー開閉ボタン */}
			<MenuSwichButton />
		</AppShell>
	)
}

export default Template;
