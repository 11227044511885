// zustand
import { create } from 'zustand';

// チャプター一覧の定義
type ChapterStoreType = {
	chapters: any;
	setChapters: (Chapters: any) => void;
	chapterid: string;
	setChapterid: (Chapter: any) => void;
};

const ChapterStore = create<ChapterStoreType>((set) => ({
	chapters: [],  //デフォルト
	setChapters: (Chapters) => set({ chapters: Chapters }),
	chapterid: 'all',  //デフォルト
	setChapterid: (Chapter) => set({ chapterid: Chapter }),
}));

export default ChapterStore;
