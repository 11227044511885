// mantine
import { Grid, Divider } from '@mantine/core';

// カラー画面ロード
import PrimaryColorSwitch from '../theme/PrimaryColorSwitch';

function MainfooterTemplate() {
	return (
		<Grid mt={60}>
			{/* 設定枠 */}
			<Grid.Col span={{ base: 12, md: 4}}>
				<Divider my="lg" label="設定" labelPosition="left" />
			</Grid.Col>
			{/* カラー枠 */}
			<Grid.Col span={{ base: 12, md: 8}}>
				<Divider my="lg" label="Color" labelPosition="left" />
				<PrimaryColorSwitch />
			</Grid.Col>
		</Grid>
	)
}

export default MainfooterTemplate;
