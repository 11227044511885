// mantine
import { MantineProvider, createTheme } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

// カラーストアのロード
import { MyColortheme } from './theme/colortheme';

// Template画面のロード
import Template from './template/Template';

function App() {
	// ローカルストレージカラー
	const [themevalue] = useLocalStorage({
		key: 'color',
		defaultValue: 'cyan',
	});

	// primaryColor設定
	const themes = createTheme({
		colors: MyColortheme,
		primaryColor: themevalue,
	});

	return (
		<MantineProvider defaultColorScheme="dark" theme={themes}>
			<Template />
		</MantineProvider>
	);
}

export default App;
