// ルート設定
import { Routes, Route } from "react-router-dom";
// mantine

// nav画面のロード
import NavMenu from '../navbar/NavMenu';
import NavCategory from '../navbar/NavCategory';
import NavChapter from '../navbar/NavChapter';
import TextEditSwich from '../navbar/TextEditSwich';

function NavbarTemplate() {
	return (
		<>
			<NavMenu />
			{/* 本文テキスト画面(チャプター一覧) */}
			<Routes>
				<Route path={`/text/:textId`} element={<><NavChapter /><TextEditSwich /></>} />
				<Route path={`*`} element='' />
			</Routes>
			{/* カテゴリ一覧 */}
			<NavCategory />
		</>
	)
}

export default NavbarTemplate;
