// zustand
import { create } from 'zustand';

// メニュー展開の定義
type MenuStoreType = {
	mobileopen: any;
	setMobileMenu: (menuopen: any) => void;
	desktopopen: any;
	setDesktopMenu: (menuopen: any) => void;
};

const MenuStore = create<MenuStoreType>((set) => ({
	mobileopen: false,  //デフォルト(閉じる)
	setMobileMenu: (open) => set({ mobileopen: open }),
	desktopopen: true,  //デフォルト(開く)
	setDesktopMenu: (open) => set({ desktopopen: open }),
}));

export default MenuStore;
