import { useEffect } from 'react';
// mantine

// ノベルストアのロード
import NovelStore from './NovelStore';

// APIのURL
const envApiUrl = process.env.REACT_APP_API_URL;
const ApiUrl = `${envApiUrl}/search.php?type=ALL&table=fullnovel`


function GetNovelData() {
	// ノベルストアの読み出し
	const setNovels = NovelStore((state) => state.setNovels)

	// APIから取得したデータを処理するためのuseStateフックを使用します
	useEffect(() => {
		// データを取得する非同期関数を定義します
		async function fetchData() {
		try {
			// APIからデータを取得します
			const response = await fetch(ApiUrl);
			// レスポンスがOKでない場合はエラーを投げます
			if (!response.ok) {
			throw new Error('APIからデータを取得できませんでした.');
			}
			// データをJSON形式で取得し、親コンポーネントに渡します
			const data = await response.json();
			setNovels(data.result);
		} catch (error) {
			// エラーが発生した場合はコンソールにログを出力し、親コンポーネントにnullを返します
			console.error('There was a problem with fetching the data:', error);
			// エラー処理を行う場合はここに記述します
		}
	}
	// データを取得する関数を実行します
	fetchData();
}, []); // apiUrlが変更されたときに再実行されます

	return null
}

export default GetNovelData;
