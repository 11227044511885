import { useState, useEffect } from 'react';
// ルート設定
import { Link } from "react-router-dom";
// mantine
import { NavLink } from '@mantine/core';
import { IconBook2, IconFolders } from '@tabler/icons-react';

// メニュー開閉のロード
import MenuStore from '../database/MenuStore';

// カテゴリ・ノベルストアのロード
import CategoryStore from '../database/CategoryStore';
import NovelStore from '../database/NovelStore';


function NavCategory() {

	// ストアデータを取得
	const categorys = CategoryStore((state) => state.categorys)
	const categoryid = CategoryStore((state) => state.categoryid)
	const novels = NovelStore((state) => state.novels)
	const novelid = NovelStore((state) => state.novelid)

	// 各ストアの読み出し
	const setMobileMenu = MenuStore((state) => state.setMobileMenu)

	// アクティブ状態ステータス
	const [categoryactive, setCategoryActive] = useState('');
	const [novelactive, setNovelActive] = useState('');

	// 現在のアクティブ状態をページ情報から取得
	useEffect(() => {
		if (categoryactive !== categoryid && categoryid !== undefined) {
			setCategoryActive(categoryid)
			}
			if (novelactive !== novelid && novelid !== undefined) {
				setNovelActive(novelid)
				}
			}, [categoryid,novelid]); // categoryid,novelidが変更されたときに再実行されます


	// ノベルリストをカテゴリでフィルター
	const novelfiltered = (category: string) => {
		return novels.filter((item: any) =>
			item.categoryid.includes(category)
		)
	};

	// ノベルメニュー生成
	const novelmenu = (categoryid: any) => {
		return novelfiltered(categoryid).map((item: any) => (
			<NavLink
				key={item.id}
				active={item.id === novelactive}
				label={item.name}
				leftSection={<IconFolders size="1rem" stroke={1.5} />}
				description={`${item.status} ${item.days}`}
				className='menuborder'
				component={Link}
				to={`/novel/${item.id}/all`}
				onClick={() => (setNovelActive(item.id), setMobileMenu(false))}
			/>
		))
	};

	// カテゴリクリック処理
	function onClickCategory(categoryid: string) {
		if (categoryactive !== categoryid) {
			setCategoryActive(categoryid)
		} else {
			setCategoryActive('')
		}
	}

	// カテゴリメニュー生成
	const categorymenu = categorys.map((item: any) => (
			<NavLink
			key={'categorymenu_' + item.id}
			id={'categorymenu_' + item.name}
			active={item.id === categoryactive}
			label={item.name}
			description={item.memo}   //説明文
			leftSection={<IconBook2 size="1rem" stroke={1.5} />}
			opened={categoryactive === item.id}   //1つだけ展開する
			onClick={() => onClickCategory(item.id)}   //アクティブ状態更新
		>
			{novelmenu(item.id)}
		</NavLink>
	));

	return (
		<>
			{categorymenu}
		</>
	)
}

export default NavCategory;
