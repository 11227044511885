import { useEffect } from 'react';
// ルート設定
import { useParams } from 'react-router-dom';
// mantine
import { Box, Title, Text } from '@mantine/core';
import { useSessionStorage } from '@mantine/hooks';

// カテゴリ・ノベルストアのロード
import CategoryStore from '../database/CategoryStore';
import NovelStore from '../database/NovelStore';
// チャプターストアのロード
import ChapterStore from '../database/ChapterStore';
// キャラクターストアのロード
import CharacterStore from '../database/CharacterStore';

// APIのURL
const envApiUrl = process.env.REACT_APP_API_URL;

function NovelHeader() {
	// URLからnovelIdを取得
	const { novelId } = useParams<string>();
	const { chapterId } = useParams<string>();

	// 各ストアの読み出し
	const { categoryid, setCategoryid } = CategoryStore();
	const { novelid, setNovelid } = NovelStore();
	const novels = NovelStore((state) => state.novels)
	const { chapterid, setChapterid } = ChapterStore();
	const setChapters = ChapterStore((state) => state.setChapters)
	const setCategoryCharacter = CharacterStore((state) => state.setCategoryCharacter)
	const setCharacter = CharacterStore((state) => state.setCharacter)

	const [ActivePage, setActivePage] = useSessionStorage({
		key: 'ActivePage',
		defaultValue: 1,
	})

	// 今のノベルidを保存
	useEffect(() => {
		// 今のノベルid報を保存
		function saveNovel() {
			// ノベルidが未定の場合はスキップ
			if (novelId === novelid || novelId === undefined) return
			// ノベルidを保存
			setNovelid(novelId)
			//ページ数変更
			setActivePage(1)
		}
		// 今のノベル情報を保存
		saveNovel();
	}, [novelId]); // novelIdが変更されたときに再実行されます

	// 今のチャプターidを保存
	useEffect(() => {
		// 今のチャプターid報を保存
		function saveActivePage() {
			// ノベルidが未定の場合はスキップ
			if (chapterId === chapterid || chapterId === undefined) return
			// チャプターidを保存
			setChapterid(chapterId)
			//ページ数変更
			setActivePage(1)
		}
		// 今のチャプター情報を保存
		saveActivePage();
	}, [chapterId]); // chapterIdが変更されたときに再実行されます

	// 今のカテゴリidを保存
	useEffect(() => {
		// 今のノベルidを保存
		function saveCategory() {
			// novelsとnovelIdがまだ読みこめていない場合はスキップ
			if (novels.length === 0 || novels === undefined || novelId === undefined) return
			// カテゴリidが存在しない場合は初期値
			if (novels.filter((item: any) => item.id === novelId).length === 0) return setCategoryid('')
			// 今のノベルデータ取得
			const novelitem = novels.filter((item: any) => item.id === novelId)[0]
			// カテゴリidを保存
			if (novelitem.categoryid !== '' && novelitem.categoryid !== undefined) {
				setCategoryid(novelitem.categoryid)
			} else {
				setCategoryid('')
			}
		}
		// 今のカテゴリidを保存
		saveCategory();
	}, [novels,novelId]); // novels,novelIdが変更されたときに再実行されます

	// データを取得する非同期関数を定義します
	async function fetchData(table: string, column: string, id: any, setfun: any) {
		// novelidが初期値はスキップ
		if (novelid === '' || novelid === undefined || id === '' || id === undefined) return
		// APIURLの生成
		const apiURL = `${envApiUrl}/search.php?type=SELECTCOLUMNALL&table=${table}&column=${column}&word=${id}`
		try {
			// APIからデータを取得します
			const response = await fetch(apiURL);
			// レスポンスがOKでない場合はエラーを投げます
			if (!response.ok) {
				throw new Error('APIからデータを取得できませんでした.');
			}
			// データをJSON形式で取得し、親コンポーネントに渡します
			const data = await response.json();
			if (data.result.length === 0) {
				setfun([])
			} else {
				setfun(data.result)
			}
		} catch (error) {
			// エラーが発生した場合はコンソールにログを出力し、親コンポーネントにnullを返します
			console.error('There was a problem with fetching the data:', error);
			// エラー処理を行う場合はここに記述します
			setfun([])
		}
	}

	// キャラクター,チャプター情報取得
	useEffect(() => {
		// データを取得する関数を実行します
		fetchData('chapters', 'novelid', novelId, setChapters);
		// データを取得する関数を実行します(ノベル別 キャラクター)
		fetchData('characters', 'type', novelid, setCharacter)
	}, [novelid]); // novelidが変更されたときに再実行されます

	// キャラクター情報取得
	useEffect(() => {
			// カテゴリidを保存
			if (categoryid !== '' && categoryid !== undefined) {
				// データを取得する関数を実行します(カテゴリ別 キャラクター)
				fetchData('characters', 'type',categoryid  , setCategoryCharacter)
			}
	}, [categoryid]); // categoryidが変更されたときに再実行されます

	// ヘッター表示関数
	function novelhrader() {
		// 今のノベルデータ取得
		const novelitem = novels.filter((item: any) => item.id === novelId)[0]
		// novelidとnovelsとnovelitemが未定の場合はID不明と表示する
		if (novelid === '' || novels.length === 0 || novels === undefined || novelitem === undefined || novelitem.length === 0 ) {
			return (
				<Title order={3}>「{novelId}」のIDは存在しません。</Title>
			)
		} else {
			return (
				<Box mb={40}>
					<Text c='dimmed' fz='sm'>{ novelitem.categoryname }</Text>
					<Title>{novelitem.name}</Title>
				</Box>
			)
		}
	}

	return (
		<>
			{novelhrader()}
		</>
	)
}

export default NovelHeader;
