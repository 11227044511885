// ルート設定
import { Link } from 'react-router-dom';
// mantine
import { Stack, Button } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';

// タイトルストアのロード
import TitleStore from '../database/TitleStore';

// ページネーションボタン
const buttonProps_left = {
	justify: "space-between", variant: "default",
	rightSection: <span />,
	leftSection: <IconChevronsLeft size={14} />,
};
const buttonProps_right = {
	justify: "space-between", variant: "default",
	leftSection: <span />,
	rightSection: <IconChevronsRight size={14} />,
};

function TextPageNation() {
	// 各ストアの読み出し
	const title = TitleStore((state) => state.title)

	// スクロール用フック
	const [scroll, scrollTo] = useWindowScroll();

	// ページネーション
	function PageNationButton(direction: string, title: string, id: string) {
		const buttonProps = direction === 'left' ? buttonProps_left : buttonProps_right
		if (id === '') {
			return (
				<Button
					{...buttonProps}
					disabled
				>
					no title
				</Button>
			)
		} else {
			return (
				<Button
					{...buttonProps}
					component={Link}
					to={`/text/${id}`}
					onClick={() => scrollTo({ y: 0 })}
		>
					{title}
				</Button>
			)
		}
	}


	// データが無い場合はスキップ
	if (title === undefined || Object.keys(title).length === 0) {
		return (
			<></>
		)
	} else {
	// ページネーション表示
	return (
			<Stack my="lg">
				{PageNationButton('left', title.backtitle ,title.backid)}
				{PageNationButton('right', title.nexttitle ,title.nextid)}
			</Stack>
		)
	}

}

export default TextPageNation;
